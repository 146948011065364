import React, { useEffect, useMemo, useState } from "react"
import { Button, Space } from "antd"
import {
  CaretRightOutlined,
  HeartFilled,
  HeartOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons"
import { formatDate, formatPrice } from "../utils"
import { InformationIcon } from "./customIcons"
import { SUBSCRIPTION } from "../enums/PricePolicies"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Countdown from "./Countdown"
import { navigate } from "gatsby"
import moment from "moment"

export const PlayButton = props => {
  const { t } = useTranslation()
  return (
    <Button {...props} className="red">
      <CaretRightOutlined /> {t("button:play")}
    </Button>
  )
}

export const PurchaseButton = props => {
  const { price, currency, pricePolicy, specialPrice, ...otherProps } = props
  const { t } = useTranslation()

  const [finalPrice, setFinalPrice] = useState(price)
  const [specialPriceHint, setSpecialPriceHint] = useState(null)
  const [countdownDuration, setCountdownDuration] = useState(0)

  useEffect(() => {
    if (!specialPrice) {
      return
    }

    const { final_price, start_at, end_at } = specialPrice
    const now = moment()

    if (start_at && moment(start_at) > now) {
      return
    }

    if (end_at && moment(end_at) < now) {
      return
    }

    setFinalPrice(final_price)

    if (end_at) {
      setSpecialPriceHint(
        t("label:specialPriceEndAt", { endAt: formatDate(end_at) })
      )

      setCountdownDuration(moment(end_at) - now)
    } else {
      setSpecialPriceHint(t("label:specialPriceEndShortly"))
    }
  }, [specialPrice])

  const priceLabel = useMemo(() => {
    if (price === finalPrice) {
      return (
        <span style={{ paddingLeft: 5 }}>{formatPrice(price, currency)}</span>
      )
    }

    return (
      <span>
        <span className="stroked-price">{formatPrice(price, currency)}</span>
        <span className="final-price">{formatPrice(finalPrice, currency)}</span>
      </span>
    )
  }, [finalPrice, price])

  if (pricePolicy === SUBSCRIPTION) {
    return (
      <Button {...otherProps} className="purple">
        <ShoppingCartOutlined /> {t("button:subscribeNow")}
      </Button>
    )
  }

  return (
    <div>
      <Button {...otherProps} className="purple">
        <ShoppingCartOutlined /> {t("button:purchase")} {priceLabel}
      </Button>
      {specialPriceHint && <div className="tag-end-at">{specialPriceHint}</div>}
    </div>
  )
}

export const StartExamButton = props => {
  const { t } = useTranslation()
  return (
    <Button {...props} className="purple">
      {t("button:startExam")}
    </Button>
  )
}

export const StartExamWithCoolDownButton = props => {
  const { t } = useTranslation()
  const { coolDown, examUrl } = props
  const [expired, setExpired] = useState(false)

  return (
    <Button
      {...props}
      type="primary"
      className="purple"
      onClick={() => {
        if (expired) {
          navigate(examUrl)
        }
      }}
    >
      <Space>
        <span>
          {t(expired ? "button:startExam" : "button:startExamAfterCoolDown")}
        </span>
        {!expired && (
          <Countdown
            duration={coolDown}
            onExpire={() => {
              setExpired(true)
            }}
          />
        )}
      </Space>
    </Button>
  )
}

export const RequireCertificationShippingButton = props => {
  const { t } = useTranslation()
  return (
    <Button {...props} className="green">
      {t("button:requireCertificationShipping")}
    </Button>
  )
}

export const MoreInfoButton = props => {
  const { t } = useTranslation()

  return (
    <Button {...props} className="white">
      <InformationIcon /> {t("button:learnMore")}
    </Button>
  )
}

export const FavoriteButton = props => {
  const { isFavorite, ...otherProps } = props
  return (
    <Button
      {...otherProps}
      className={isFavorite ? "red" : ""}
      type={isFavorite ? "primary" : "ghost"}
      shape="circle"
    >
      {isFavorite ? <HeartFilled /> : <HeartOutlined />}
    </Button>
  )
}

FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool,
}

export const FollowButton = props => {
  const { t } = useTranslation()

  const { isFavorite, ...otherProps } = props
  return (
    <Button
      {...otherProps}
      className={isFavorite ? "red" : ""}
      type={isFavorite ? "primary" : "ghost"}
    >
      {isFavorite ? (
        <>
          <HeartFilled /> {t("button:following")}
        </>
      ) : (
        <>
          <HeartOutlined /> {t("button:follow")}
        </>
      )}
    </Button>
  )
}
