import React, { useMemo } from "react"
import "./CertificationTile.less"
import EventureCard from "./EventureCard"
import { Button, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"
import { devLog, getImageUrl, translatePath } from "../utils"
import {
  FavoriteButton,
  StartExamButton,
  StartExamWithCoolDownButton,
} from "./Buttons"
import useFavorite from "../hooks/useFavorite"
import { CERTIFICATION } from "../enums/ItemTypes"
import { InformationIcon } from "./customIcons"

const CertificationTile = ({
  data,
  onClick,
  width,
  height,
  badge,
  passed = false,
  canPurchase = true,
  loading,
  onToggleFavorite,
  onPurchase,
  onShowInfo,
  lockedDuration,
  canTakeExam = false,
  canRequireShipping,
  extra,
}) => {
  const {
    id,
    title,
    subtitle,
    short_description,
    exam,
    price,
    seo,
    landscape_success,
    landscape_locked,
  } = data

  const { t } = useTranslation()
  const [isFavorite, setIsFavorite] = useFavorite(id, CERTIFICATION)

  const lockedImage = getImageUrl(landscape_locked)
  const successImage = getImageUrl(landscape_success)

  let cover = passed ? successImage : lockedImage

  const _onToggleFavorite = () => {
    setIsFavorite(!isFavorite)
  }

  const favoriteButton = useMemo(() => {
    return (
      <FavoriteButton
        isFavorite={isFavorite}
        onClick={() => {
          onToggleFavorite(data)
          _onToggleFavorite()
        }}
      />
    )
  }, [isFavorite])

  const actions = useMemo(() => {
    if (loading) {
      return [<Spin spinning />]
    }

    devLog({ title, canTakeExam })

    if (canPurchase) {
      return [
        <Button
          className="purple"
          onClick={() => {
            onClick && onClick(data)
          }}
        >
          {t("button:getItNow")}
        </Button>,
      ]
    }

    if (canTakeExam) {
      return [
        <StartExamButton
          onClick={() => {
            navigate(
              `/${translatePath("certification")}/${seo.slug}/${translatePath(
                "exam"
              )}`
            )
          }}
        />,
      ]
    }

    if (lockedDuration > 0) {
      return [
        <StartExamWithCoolDownButton
          coolDown={lockedDuration}
          examUrl={`/${translatePath("certification")}/${
            seo.slug
          }/${translatePath("exam")}`}
        />,
      ]
    }

    // if (canRequireShipping) {
    //   return [
    //     <RequireCertificationShippingButton
    //       onClick={() => {
    //         navigate(`/online-tests/require-shipping?id=${id}`)
    //       }}
    //     />,
    //   ]
    // }

    return [
      <Button
        onClick={() => {
          onShowInfo(data)
          navigate(`/${translatePath("certification")}/${seo.slug}`)
        }}
        className="white"
      >
        <InformationIcon /> {t("button:examPassed")}
      </Button>,
    ]
  }, [
    data,
    passed,
    canPurchase,
    loading,
    isFavorite,
    canTakeExam,
    lockedDuration,
  ])

  return (
    <div className={`certification-tile ${passed ? "passed" : ""}`}>
      <EventureCard
        extra={extra}
        width={width}
        height={height}
        onClick={() => {
          onClick(data)
        }}
        type="full"
        title={title}
        subtitle={subtitle}
        description={short_description}
        cover={cover}
        badge={badge}
        actions={actions}
      />
    </div>
  )
}

CertificationTile.defaultProps = {
  type: "compact",
  onClick: () => {},
  onPlay: () => {},
  onShowInfo: () => {},
  onToggleFavorite: () => {},
}

export default CertificationTile
