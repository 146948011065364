import React, { useMemo } from "react"
import PropTypes from "prop-types"
import "./VideoTile.less"
import EventureCard from "./EventureCard"
import { AuthorList, ComingSoon, Duration, Level } from "./Labels"
import { FavoriteButton, MoreInfoButton } from "./Buttons"
import useFavorite from "../hooks/useFavorite"
import { VIDEO } from "../enums/ItemTypes"
import { getImageUrl } from "../utils"
import eventureLogo from "../../static/assets/images/ico-card.svg"
import { COMING_SOON } from "../enums/RecordStates"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const VideoTile = ({
  data,
  onClick,
  type = "compact",
  onPlay,
  onShowInfo,
  onToggleFavorite,
  badge,
  progress,
  className,
  rank,
}) => {
  const {
    id,
    state,
    title,
    subtitle,
    short_description,
    duration,
    landscape,
    author_rels,
    created_at,
    price_policy,
    level,
    available_date,
  } = data

  const [isFavorite, setIsFavorite] = useFavorite(id, VIDEO)

  const handleClick = () => {
    onClick && onClick(data)
  }

  const handleFavorite = () => {
    setIsFavorite(!isFavorite)
  }

  const coverUrl = getImageUrl(landscape, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  const actions = useMemo(() => {
    let result = []

    // if (state === PUBLISHED) {
    //   result.push(
    //     <PlayButton
    //       onClick={() => {
    //         onPlay(data)
    //       }}
    //     />
    //   )
    // }

    return [
      ...result,
      <MoreInfoButton
        onClick={() => {
          onShowInfo(data)
          onClick && onClick(data)
        }}
      />,
      <FavoriteButton
        isFavorite={isFavorite}
        onClick={() => {
          onToggleFavorite(data)
          handleFavorite()
        }}
      />,
    ]
  }, [data, isFavorite, state])

  const extra = useMemo(() => {
    const result = []

    if (state === COMING_SOON) {
      result.push(<ComingSoon availableDate={available_date} />)
    }

    if (duration) {
      result.push(<Duration totalTime={duration} />)
    }

    return [
      ...result,
      author_rels && (
        <AuthorList authorList={author_rels.map(item => item.author)} />
      ),
      <Level level={level} />,
    ]
  }, [duration, author_rels, level, state, available_date])

  return (
    <div className={`video-tile ${className}`}>
      <EventureCard
        title={title}
        subtitle={subtitle}
        description={short_description}
        cover={coverUrl}
        badge={badge}
        rank={
          rank || (
            <div className="icon-wrapper">
              <img src={eventureLogo} alt="" />
            </div>
          )
        }
        progress={progress}
        actions={actions}
        extra={extra}
        type={type}
        onClick={handleClick}
      />
    </div>
  )
}

VideoTile.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onPlay: PropTypes.func,
  onShowInfo: PropTypes.func,
  onToggleFavorite: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  badge: PropTypes.node,
  type: PropTypes.oneOf(["full", "compact"]),
}

VideoTile.defaultProps = {
  type: "compact",
  onClick: () => {},
  onPlay: () => {},
  onShowInfo: () => {},
  onToggleFavorite: () => {},
}

export default VideoTile
