import React, { useEffect, useState } from "react"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { gql, useSubscription } from "@apollo/client"
import { SET_USER_DATA } from "../enums/DispatchTypes"
import { useAuth0 } from "@auth0/auth0-react"
import { devLog, isSubscriptionValid, uiHandleError } from "../utils"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import GdprContent from "./gdpr/gdpr-consent"
import useBraintree from "../hooks/useBraintree"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"
import useScript from "../hooks/useScript"

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://910d327984f84a1ea1a81326b7ec6a43@o532738.ingest.sentry.io/5651997",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      const error = hint.originalException
      if (error && error.message && error.message.match(/Login required/i)) {
        return null
      }
      return event
    },
  })
}

const SUBSCRIBE_USER_DATA = gql`
  subscription subscribeUserData($user_id: String!) {
    auth0_users_by_pk(user_id: $user_id) {
      role
      user_id
      last_login
      braintree_customer {
        customer_id
      }
      basic_profile {
        id
        firstname
        lastname
        email
        phone_number
        newsletter
      }
      billing_profile {
        id
        firstname
        lastname
      }
      inventory_items {
        item_id
        item_type
        created_at
        certification_state {
          exam_submit_at
          last_exam_at
          passed
          shipped
          shipping_required
        }
      }
      subscriptions(order_by: { end_at: desc_nulls_last }, limit: 1) {
        state
        created_at
        end_at
        id
        start_at
        user_id
        braintree_subscription_id
      }
      favorites {
        item_id
        item_type
        is_favorite
      }
    }
  }
`

const UserDataSubscriber = ({ user }) => {
  const [{}, dispatch] = useGlobalValue()
  const { autoRenew } = useBraintree()
  const { t } = useTranslation()

  const { data, loading, error } = useSubscription(SUBSCRIBE_USER_DATA, {
    variables: { user_id: user?.sub },
  })

  const autoRenewForScienzeMotorieUsers = subscription => {
    if (!isSubscriptionValid(subscription)) {
      if (
        subscription.state !== "Canceled" &&
        !subscription.braintree_subscription_id
      ) {
        autoRenew()
          .then(() => {
            console.log("auto renew success")
          })
          .catch(error => {
            // handle auto renew error
            uiHandleError({
              message: t("error:cannotAutoRenew"),
              action: () => {
                navigate("/subscribe")
              },
              actionText: t("button:renewNow"),
            })

            console.log({ autoRenewError: error })
          })
      }
    }
  }

  useEffect(() => {
    devLog("Subscription")
    devLog({ loading, data, error })
    if (!loading) {
      dispatch({
        type: SET_USER_DATA,
        value: data?.auth0_users_by_pk,
      })

      if (!error) {
        // check subscription
        const subscription = data.auth0_users_by_pk.subscriptions
          ? data.auth0_users_by_pk.subscriptions[0]
          : null
        if (subscription) {
          autoRenewForScienzeMotorieUsers(subscription)
        }
      }
    }
  }, [loading, data, error])

  return null
}

const FirstRun = () => {
  const { user, isLoading, error } = useAuth0()
  const { t } = useTranslation()
  const [oneSignalInstance, setOneSignalInstance] = useState(null)

  useScript({
    url: "https://cdn.onesignal.com/sdks/OneSignalSDK.js",
    onLoad: () => {
      const OneSignal = (window.OneSignal = window.OneSignal || [])
      setOneSignalInstance(OneSignal)
      OneSignal.push(function () {
        OneSignal.init({
          appId: process.env.GATSBY_ONESIGNAL_APP_ID,
          safari_web_id: process.env.GATSBY_ONESIGNAL_SAFARI_WEB_ID,
          allowLocalhostAsSecureOrigin: true,
          promptOptions: {
            slidedown: {
              prompts: [
                {
                  type: "push", // current types are "push" & "category"
                  autoPrompt: true,
                  text: {
                    /* limited to 90 characters */
                    actionMessage: t("notification:actionMessage"),
                    /* acceptButton limited to 15 characters */
                    acceptButton: t("button:accept"),
                    /* cancelButton limited to 15 characters */
                    cancelButton: t("button:cancel"),
                  },
                  delay: {
                    pageViews: 1,
                    timeDelay: 5,
                  },
                },
              ],
            },
          },
        })
      })
    },
    onError: error => {},
  })

  useEffect(() => {
    if (oneSignalInstance && user) {
      oneSignalInstance.push(function () {
        oneSignalInstance.setExternalUserId(user.sub)
      })
    }

    ;(function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          ;(h.hj.q = h.hj.q || []).push(arguments)
        }
      h._hjSettings = { hjid: process.env.GATSBY_HOTJAR_ID, hjsv: 6 }
      a = o.getElementsByTagName("head")[0]
      r = o.createElement("script")
      r.async = 1
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
      a.appendChild(r)
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
  }, [oneSignalInstance, user])

  useEffect(() => {
    const redirect = localStorage.getItem("redirect")
    if (redirect) {
      const url = decodeURIComponent(redirect)
      window.location.replace(url)
      localStorage.removeItem("redirect")
    }
  }, [])

  useEffect(() => {
    devLog({ error })
    if (error) {
      uiHandleError({ error })
    }
  }, [error])

  if (!isLoading) {
    return (
      <>
        <UserDataSubscriber user={user} />
        <GdprContent />
      </>
    )
  }

  return null
}

export default FirstRun
