import React from "react"
import EventureCard from "./EventureCard"
import "./ChannelTile.less"
import PropTypes from "prop-types"
import { getImageUrl } from "../utils"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const ChannelTile = ({ data, onClick, width, height, badge }) => {
  const { id, name, description, portrait } = data

  const coverUrl = getImageUrl(portrait, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  return (
    <div className="channel-tile">
      <EventureCard
        width={width}
        height={height}
        onClick={() => {
          onClick(data)
        }}
        type="full"
        title={name}
        subtitle={description}
        cover={coverUrl}
        badge={badge}
        // extra={[
        //   <VideoCount count={123} />,
        //   <ArticleCount count={123} />,
        //   <ProductCount count={123} />]}
      />
    </div>
  )
}

ChannelTile.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badge: PropTypes.node,
}

ChannelTile.defaultProps = {
  height: "auto",
  onClick: () => {},
}

export default ChannelTile
