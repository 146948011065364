import React from "react"
import EventureCard from "./EventureCard"
import "./CategoryTile.less"
import PropTypes from "prop-types"
import { getImageUrl } from "../utils"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const CategoryTile = ({ data, onClick, width, height, badge }) => {
  const { id, name, description, landscape } = data

  const coverUrl = getImageUrl(landscape, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  return (
    <div className="category-tile">
      <EventureCard
        width={width}
        height={height}
        onClick={() => {
          onClick(data)
        }}
        type="full"
        title={name}
        subtitle={null}
        cover={coverUrl}
        badge={badge}
        // extra={[
        //   <VideoCount count={123} />,
        //   <ArticleCount count={123} />,
        //   <ProductCount count={123} />]}
      />
    </div>
  )
}

CategoryTile.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badge: PropTypes.node,
}

CategoryTile.defaultProps = {
  height: "auto",
  onClick: () => {},
}

export default CategoryTile
