import React from "react"
import EventureCard from "./EventureCard"
import { VideoCount } from "./Labels"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { getImageUrl, translatePath } from "../utils"
import { DEFAULT_AUTHOR_IMAGE_SIZE } from "../constants/imageSizes"

const AuthorTile = ({
  data,
  onClick,
  width,
  height,
  badge,
  direction,
  className,
}) => {
  const {
    id,
    firstname,
    lastname,
    title,
    biography,
    short_biography,
    picture,
    video_rels,
    seo,
  } = data

  const coverUrl = getImageUrl(picture, DEFAULT_AUTHOR_IMAGE_SIZE)

  return (
    <div className={`author-tile ${className}`}>
      <EventureCard
        cover={coverUrl}
        width={width}
        height={height}
        onClick={() => {
          // navigate(`/${translatePath("speakers")}/${seo.slug}`)
          onClick(data)
        }}
        type="full"
        title={`${firstname} ${lastname}`}
        description={short_biography}
        subtitle={title}
        direction={direction}
        badge={badge}
        extra={[<VideoCount count={video_rels?.length ?? 0} />]}
      />
    </div>
  )
}

AuthorTile.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badge: PropTypes.node,
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
}

AuthorTile.defaultProps = {
  height: "auto",
  direction: "vertical",
  onClick: () => {},
  className: "",
}

export default AuthorTile
