import React, { useMemo } from "react"
import "./EventureCard.less"
import PropTypes from "prop-types"
import { Progress, Space } from "antd"
import { defaultTheme } from "../theme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LazyWrapper from "./LazyWrapper"

const EventureCard = ({
  cover,
  onClick,
  title,
  subtitle,
  description,
  actions,
  extra,
  badge,
  type,
  width,
  height,
  progress,
  direction,
  disabled,
  rank,
}) => {
  let showContent = Boolean(
    !!title |
      !!subtitle |
      !!description |
      (extra?.length > 0) |
      (actions?.length > 0)
  )

  const coverElement = useMemo(() => {
    if (cover?.localFile?.childImageSharp?.gatsbyImageData) {
      const imageData = getImage(cover?.localFile)
      return (
        <GatsbyImage
          style={{ width: "100%" }}
          image={imageData}
          alt={title || "cover"}
        />
      )
    }

    if (typeof cover === "string") {
      return <img src={cover || defaultTheme.defaultLandscape} alt={title} />
    }

    return cover
  }, [cover])

  return (
    <LazyWrapper>
      <div
        className={`eventure-card ${direction} ${disabled ? "disabled" : ""}`}
        style={{ width: width }}
      >
        <Space direction={direction} size={0}>
          <div className={"cover"} onClick={onClick} style={{ height: height }}>
            {coverElement}
            {badge && <div className="badge-wrapper">{badge}</div>}
            {rank && (
              <div className="rank-wrapper">
                <div className="rank-number">{rank}</div>
              </div>
            )}
            {progress >= 0 && (
              <div className="progress-wrapper">
                <Progress
                  percent={progress}
                  showInfo={false}
                  strokeWidth={12}
                />
              </div>
            )}
          </div>
          {showContent && (type !== "compact" || direction !== "vertical") && (
            <div className="content">
              <h4 className="secondary">{subtitle}</h4>
              <h2 className="title">{title}</h2>
              <div className="extra-wrapper">
                <Space>
                  {extra &&
                    extra.map((item, index) => <div key={index}>{item}</div>)}
                </Space>
              </div>
            </div>
          )}
        </Space>

        {type !== "compact" && (
          <div className="projection">
            <Space direction={direction} size={0}>
              <div
                className={"cover"}
                onClick={onClick}
                style={{ height: height }}
              >
                {coverElement}
                {badge && <div className="badge-wrapper">{badge}</div>}
                {rank && (
                  <div className="rank-wrapper">
                    <div className="rank-number">{rank}</div>
                  </div>
                )}
                {progress >= 0 && (
                  <div className="progress-wrapper">
                    <Progress
                      percent={progress}
                      showInfo={false}
                      strokeWidth={12}
                    />
                  </div>
                )}
              </div>
              {showContent && (
                <div className="content">
                  <div className="actions-wrapper">
                    <Space>
                      {actions &&
                        actions.map((item, index) => (
                          <div key={index}>{item}</div>
                        ))}
                    </Space>
                  </div>
                  <h4 className="secondary">{subtitle}</h4>
                  <h2 className="title">{title}</h2>
                  <div>
                    <p className="description">{description}</p>
                  </div>
                  <div className="extra-wrapper">
                    <Space>{extra && extra.map(item => item)}</Space>
                  </div>
                </div>
              )}
            </Space>
          </div>
        )}
      </div>
    </LazyWrapper>
  )
}

EventureCard.propTypes = {
  cover: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
  ]),
  onClick: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.node),
  extra: PropTypes.arrayOf(PropTypes.node),
  type: PropTypes.oneOf(["full", "compact"]),
  badge: PropTypes.node,
  rank: PropTypes.any,
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
}

EventureCard.defaultProps = {
  type: "compact",
  width: "auto",
  height: "auto",
  rank: null,
  direction: "vertical",
}

export default EventureCard
