import React, { useEffect, useState } from "react"
import { Button, Col, Modal, Row, Space } from "antd"
import { useTranslation } from "react-i18next"
import "./gdpr-consent.less"
import useBreakpoint from "../../hooks/useBreakpoint"
import Cookies from "js-cookie"
import { USER_COOKIE_CONSENT } from "../../enums/CookieKeys"

const buttonColSettings = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 12,
}

const modalWidth = {
  xs: "100%",
  sm: "100%",
  md: "100%",
  lg: 900,
  xl: 900,
  xxl: 900,
}

const GdprContent = () => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const breakpoint = useBreakpoint()

  useEffect(() => {
    const cookieConsent = Cookies.get(USER_COOKIE_CONSENT)
    if (typeof cookieConsent === "undefined") {
      setShowModal(true)
    }
  }, [])

  const denyCookies = () => {
    Cookies.set(USER_COOKIE_CONSENT, 0, { expires: 365 })
    setShowModal(false)
  }

  const consentCookies = () => {
    Cookies.set(USER_COOKIE_CONSENT, 1, { expires: 365 })
    setShowModal(false)
  }

  return (
    <Modal
      width={modalWidth[breakpoint]}
      className="cookie-consent-modal"
      title={null}
      footer={null}
      visible={showModal}
      closable={false}
    >
      <div>
        <h1 className="title">{t("cookieConsent:title")}</h1>
        <p className="description">{t("cookieConsent:description")}</p>
        <Space className="link-wrapper" size="large" wrap>
          <a href={"/privacy"} target="_blank" type="link">
            {t("cookieConsent:privacyPolicy")}
          </a>
          <a href={"/gdpr"} target="_blank" type="link">
            {t("cookieConsent:gdpr")}
          </a>
          <a href={"/cookies"} target="_blank" type="link">
            {t("cookieConsent:cookiePolicy")}
          </a>
          <a href={"/tos"} target="_blank" type="link">
            {t("cookieConsent:tos")}
          </a>
        </Space>
        <Row gutter={[16, 16]}>
          <Col {...buttonColSettings}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              className="white"
              onClick={denyCookies}
            >
              {t("button:deny")}
            </Button>
          </Col>
          <Col {...buttonColSettings}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              className="purple"
              onClick={consentCookies}
            >
              {t("button:consent")}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default GdprContent
