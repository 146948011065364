import React, { createRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./EventureCarousel.less"
import { Button, Col, Row, Skeleton } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import NoDataScreen from "./NoDataScreen"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { useTranslation } from "react-i18next"

const colSettings = {
  xs: 18,
  sm: 18,
  md: 8,
  lg: 6,
  xl: 6,
  xxl: 4,
}

const EventureCarousel = ({
  title,
  url,
  dataSource,
  renderItem,
  gutter,
  colSettings,
  loading,
  emptyItem,
  isVertical,
}) => {
  const itemListWrapperRef = createRef()

  const { t } = useTranslation()

  const [{ isLoadingUser }] = useGlobalValue()

  const scrollForward = () => {
    itemListWrapperRef.current.scrollLeft += window.innerWidth - 87
    // itemListWrapperRef.current.scrollBy({
    //   left: window.innerWidth - 87,
    //   behavior: "smooth",
    // })
  }

  const scrollBackward = () => {
    itemListWrapperRef.current.scrollLeft -= window.innerWidth - 87
  }

  const childTitle = useMemo(
    () =>
      title ? (
        <h1 className="carousel-title">
          {title}
          {url && (
            <Link className="header-link" to={url}>
              {t("button:explore")}
            </Link>
          )}
        </h1>
      ) : null,
    [title, url]
  )

  const childRow = useMemo(
    () => (
      <>
        {dataSource && dataSource.length ? (
          <Row wrap={false} gutter={gutter}>
            {dataSource.map((item, index) => {
              if (!item) {
                return null
              }
              return (
                <Col key={`eventure-carousel-${item.id}-${index}`} {...colSettings}>
                  {renderItem(item, index, undefined, undefined, isVertical)}
                </Col>
              )
            })}
          </Row>
        ) : (
          emptyItem || <NoDataScreen />
        )}
      </>
    ),
    [dataSource, isLoadingUser]
  )

  return (
    <div className="eventure-carousel">
      {childTitle}
      <div className="eventure-carousel-content">
        <div className="item-tile-list-wrapper" ref={itemListWrapperRef}>
          {loading ? <Skeleton active /> : childRow}
        </div>
        <Button
          type="ghost"
          shape="circle"
          className="slide-button prev"
          icon={<LeftOutlined />}
          onClick={scrollBackward}
        />
        <Button
          type="ghost"
          shape="circle"
          className="slide-button next"
          icon={<RightOutlined />}
          onClick={scrollForward}
        />
      </div>
    </div>
  )
}

EventureCarousel.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  dataSource: PropTypes.arrayOf(PropTypes.object),
}

EventureCarousel.defaultProps = {
  gutter: 22,
  colSettings: colSettings,
}

export default EventureCarousel
