import React from "react"
import PropTypes from "prop-types"

import "./AuthorHeader.less"
import { Col, Collapse, Row } from "antd"
import { defaultTheme } from "../theme"
import HeaderExtra from "./HeaderExtra"
import { FollowButton } from "./Buttons"
import { AUTHOR } from "../enums/ItemTypes"
import useFavorite from "../hooks/useFavorite"
import ReadOnlyQuill from "../components/ReadOnlyQuill"
import { useImageColorDetector } from "../hooks/useImageColorDetector"
import { useTranslation } from "react-i18next"
import ContainerWithPadding from "./ContainerWithPadding"

const { Panel } = Collapse

const AuthorHeader = ({ data }) => {
  const {
    id,
    firstname,
    lastname,
    title,
    picture,
    short_biography,
    biography,
    wallpaper
  } = data

  const { isDark } = useImageColorDetector({
    url: wallpaper?.localFile?.childImageSharp.fixed.src
  })

  const { t } = useTranslation()

  const [isFavorite, setIsFavorite] = useFavorite(id, AUTHOR)
  const onToggleFollow = () => {
    setIsFavorite(!isFavorite)
  }

  return (
    <div>
      <div
        className={"author-page"}
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , url(${
            wallpaper?.localFile?.childImageSharp.fixed.src ||
            defaultTheme.defaultPortrait
          }) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(61,61,61,1) 100%)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
        <ContainerWithPadding size={"large"} align={"start"}>
          <div className={`content ${isDark ? "dark" : "light"}`}>
            <Row gutter={26} direction="horizontal">
              <Col span={18} xs={24} sm={24} md={24} lg={14}>
                <h1 className="title">{`${firstname} ${lastname}`}</h1>
                <h2 className="subtitle">{title}</h2>
                <div className={"wrapper-title-info"}>
                  <FollowButton isFavorite={isFavorite} onClick={onToggleFollow} />
                </div>
                <div>
                  <ReadOnlyQuill value={short_biography} />
                </div>
                <HeaderExtra />
              </Col>
            </Row>
          </div>
        </ContainerWithPadding>
      </div>
      <ContainerWithPadding size={"large"} align={"start"}>
        <div className={"faq-wrapper"}>
          <Collapse className="author-collapse" defaultActiveKey={["bio"]}>
            <Panel header={t("label:biographyAndTitle")} key="bio">
              <ReadOnlyQuill value={biography} />
            </Panel>
          </Collapse>
        </div>

      </ContainerWithPadding>

    </div>
  )
}

export default AuthorHeader

AuthorHeader.propTypes = {
  data: PropTypes.object.isRequired
}
