import React, { useMemo } from "react"
import PropTypes from "prop-types"

import "./GenericHeader.less"
import { Col, Row, Space } from "antd"
import { defaultTheme } from "../theme"
import { useImageColorDetector } from "../hooks/useImageColorDetector"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const GenericHeader = ({ data }) => {
  const { wallpaper, title, subtitle, description, header_image, actions, home } =
    data

  const _wallpaper =
    wallpaper?.localFile?.childImageSharp.fixed.src ??
    wallpaper ??
    defaultTheme.defaultWallpaper

  const { isDark } = useImageColorDetector({
    url: _wallpaper
  })

  const image = useMemo(() => {
    if (header_image?.localFile?.childImageSharp?.gatsbyImageData) {
      const imageData = getImage(header_image?.localFile)
      return <GatsbyImage image={imageData} alt={title || "logo"} />
    }

    if (typeof header_image === "string") {
      return (
        <img src={header_image || defaultTheme.defaultLandscape} alt={title} />
      )
    }
  }, [header_image])

  return (
    <div
      className={`content-header generic ${home ? "home" : ""}`}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , url(${_wallpaper}) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(61,61,61,1) 100%)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <div className={`content ${isDark ? "dark" : "light"}`}>
        <Row
          className="info-wrapper"
          direction="vertical"
          justify="space-around"
        >
          <Col sm={24} md={20} lg={20} xl={20} xxl={20}>
            {header_image && <div className="header-logo-wrapper">{image}</div>}
            <div>
              <h1 className="title">{title}</h1>
            </div>
            <div>
              <h2 className="subtitle">{subtitle}</h2>
            </div>
            <div>
              <p className="description">{description}</p>
            </div>
            <Space direction="horizontal">
              {actions?.map(action => action)}
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default GenericHeader

GenericHeader.propTypes = {
  data: PropTypes.object.isRequired
}
