import React, { useMemo } from "react"
import "./WebinarTile.less"
import EventureCard from "./EventureCard"
import { AuthorList } from "./Labels"
import { formatDate, getImageUrl, translatePath } from "../utils"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import { navigate } from "gatsby"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const WebinarTile = ({ data, onClick, type = "compact", className }) => {
  const {
    id,
    title,
    subtitle,
    short_description,
    landscape,
    available_date,
    author,
    author_rels,
    video,
    url,
  } = data

  const { t } = useTranslation()

  const handleClick = () => {
    // onClick && onClick(data)
    if (video) {
      let videoUrl
      const courseSlug = video.course_rel?.course?.seo?.slug
      if (courseSlug) {
        videoUrl = `${translatePath("/course")}/${courseSlug}`
      } else {
        videoUrl = `${translatePath("/video")}/${video.seo?.slug}`
      }

      navigate(videoUrl)
    } else {
      window.open(url, "_blank")
    }
  }

  const coverUrl = getImageUrl(landscape, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  const actions = useMemo(() => {
    if (video) {
      return [
        <Button className="green" onClick={handleClick}>
          {t("button:onlyOnEventure")}
        </Button>,
      ]
    }

    if (url) {
      return [
        <Button className="purple" onClick={handleClick}>
          {t("button:signupForFreeToWebinar")}
        </Button>,
      ]
    }
  }, [data])

  return (
    <div className={`webinar-tile ${className}`}>
      <EventureCard
        title={title}
        subtitle={subtitle}
        description={short_description}
        cover={coverUrl}
        actions={actions}
        extra={[
          <span>{formatDate(available_date)}</span>,
          <AuthorList
            authorList={author_rels.map(({ author }) => author)}
            maxToShow={5}
          />,
        ]}
        type={type}
        onClick={handleClick}
      />
    </div>
  )
}

export default WebinarTile
