import { useEffect } from "react"

const useScript = ({ url, async = true, onLoad, onError }) => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = url
    script.async = async
    script.onload = () => {
      if (onLoad) onLoad()
    }

    script.onerror = onError

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export default useScript
