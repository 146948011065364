import React, { useMemo } from "react"
import {
  DollarCircleOutlined,
  EuroCircleOutlined,
  FileSearchOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { formatDate, formatDuration, formatPrice, getImageUrl } from "../utils"
import { Space, Tag } from "antd"
import { DurationIcon, LevelIcon, VideoCountIcon } from "./customIcons"
import { FREE, SUBSCRIPTION } from "../enums/PricePolicies"
import { useTranslation } from "react-i18next"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { defaultTheme } from "../theme"

export const Duration = ({ totalTime }) => {
  return (
    <div>
      <DurationIcon /> {formatDuration(totalTime)}
    </div>
  )
}

export const ComingSoon = ({ availableDate }) => {
  const { t } = useTranslation()
  return (
    <Tag color="purple">
      <span className="t-label">{t("label:comingSoon")}</span>{" "}
      <span className="t-date">
        {availableDate && formatDate(availableDate)}
      </span>
    </Tag>
  )
}

export const VideoCount = ({ count }) => {
  return (
    <div>
      <VideoCountIcon /> {count}
    </div>
  )
}

export const CertificationBadge = ({ title, badge }) => {
  const { t } = useTranslation()
  const imageUrl = getImageUrl(badge, "48")

  const avatar = useMemo(() => {
    if (imageUrl?.localFile?.childImageSharp?.gatsbyImageData) {
      const imageData = getImage(imageUrl?.localFile)
      return <GatsbyImage image={imageData} alt={title || "cover"} />
    }

    if (typeof imageUrl === "string") {
      return <img src={imageUrl || defaultTheme.defaultLandscape} alt={title} />
    }

    return imageUrl
  }, [imageUrl])

  return (
    <Space>
      <div
        className="avatar-container"
        style={{
          width: 48,
          height: 48,
          borderRadius: 48,
          overflow: "hidden",
        }}
      >
        {avatar}
      </div>
      <Space direction="vertical">
        <span>{t("label:youCanUnlock")}:</span>
        <h3>{title}</h3>
      </Space>
    </Space>
  )
}

export const ArticleCount = ({ count }) => {
  return (
    <div>
      <FileSearchOutlined /> {count}
    </div>
  )
}

export const ProductCount = ({ count }) => {
  return (
    <div>
      <ShoppingOutlined /> {count}
    </div>
  )
}

export const AuthorList = ({ authorList, maxToShow = 1 }) => {
  if (authorList.length > maxToShow) {
    return (
      <div>
        <UserOutlined />
        {authorList.length}
      </div>
    )
  }

  const nameLabels = authorList
    .map(author => (author ? `${author.firstname} ${author.lastname}` : ""))
    .join(", ")

  return (
    <Space>
      <UserOutlined />
      {nameLabels}
    </Space>
  )
}

export const Level = ({ level }) => {
  const { t } = useTranslation()
  return (
    <div>
      <LevelIcon />
      {t("label:level")} {level}
    </div>
  )
}

export const CertificationType = ({ type }) => {
  const { t } = useTranslation()
  return <Tag color="orange">{t(`const:${type}`)}</Tag>
}

export const Price = ({ price, pricePolicy, currency = "€" }) => {
  const { t } = useTranslation()

  let MoneyIcon = EuroCircleOutlined
  switch (process.env.GATSBY_LANG) {
    case "it_IT":
      MoneyIcon = EuroCircleOutlined
      break
    case "en_US":
      MoneyIcon = DollarCircleOutlined
      break
    default:
      MoneyIcon = EuroCircleOutlined
  }

  if (pricePolicy === SUBSCRIPTION) {
    return (
      <div>
        <MoneyIcon /> {t("label:subscriptionRequired")}
      </div>
    )
  }

  if (pricePolicy === FREE) {
    return (
      <div>
        <MoneyIcon /> {t("label:freeContent")}
      </div>
    )
  }

  return (
    <div>
      <MoneyIcon />
      {formatPrice(price, currency)}
    </div>
  )
}
