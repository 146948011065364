import React from "react"
import { Space } from "antd"
import { Link } from "gatsby"
import SocialShareButtons from "./SocialShareButtons"
import "./HeaderExtra.less"
import logoEventure from "../../static/assets/images/logo-eventure.png"

const HeaderExtra = ({ images }) => {
  return (
    <div className="header-extra-info-wrapper">
      <div className="sponsor-logo-wrapper">
        {images?.length > 0 ? (
          <Space>
            {images.map((image, index) => (
              <img key={index} src={image} alt="ATS" />
            ))}
          </Space>
        ) : (
          <Space>
            <Link to="/" target="_blank">
              <img src={logoEventure} alt="eventure" />
            </Link>
          </Space>
        )}
      </div>
      <div className="social-wrapper">
        <SocialShareButtons />
      </div>
    </div>
  )
}

export default HeaderExtra
