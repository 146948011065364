import React, { useMemo } from "react"
import PropTypes from "prop-types"

import "./VideoHeader.less"
import { Col, Row, Skeleton, Space } from "antd"
import { defaultTheme } from "../theme"
import { AuthorList, Duration, Price } from "./Labels"
import { FavoriteButton, MoreInfoButton, PlayButton } from "./Buttons"
import { useImageColorDetector } from "../hooks/useImageColorDetector"
import { COMING_SOON } from "../enums/RecordStates"
import ComingSoon from "./ComingSoon"

const VideoHeader = ({
  data,
  canPlay,
  canPurchase,
  loading,
  onPlay,
  onPurchase,
  isFavorite,
  onToggleFavorite,
  showFavoriteButton,
  onShowMoreInfo,
}) => {
  const {
    wallpaper,
    title,
    subtitle,
    description,
    short_description,
    header_image,
    price,
    special_price,
    price_policy,
    currency,
    duration,
    author_rels,
    state,
    available_date,
  } = data

  const { isDark } = useImageColorDetector({
    url: wallpaper?.localFile?.childImageSharp.fixed.src,
  })

  const authorList = author_rels.map(item => item.author)

  const videoActionButtons = useMemo(() => {
    if (loading) {
      return <Skeleton active />
    }

    if (state === COMING_SOON) {
      return <ComingSoon availableDate={available_date} />
    }

    return (
      <>
        <Space align="start">
          {canPlay && (
            <PlayButton
              onClick={() => {
                onPlay(data)
              }}
            />
          )}
          {canPurchase && (
            <MoreInfoButton
              onClick={() => {
                onShowMoreInfo && onShowMoreInfo(data)
              }}
            />
          )}
          {showFavoriteButton && (
            <FavoriteButton
              onClick={() => {
                onToggleFavorite(data)
              }}
              isFavorite={isFavorite}
            />
          )}
        </Space>
      </>
    )
  }, [
    loading,
    canPlay,
    canPurchase,
    isFavorite,
    price_policy,
    available_date,
    state,
  ])

  return (
    <div
      className={"content-header video-header"}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , url(${
          wallpaper?.localFile?.childImageSharp.fixed.src ||
          defaultTheme.defaultPortrait
        }) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(61,61,61,1) 100%)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <div className={`content ${isDark ? "dark" : "light"}`}>
        <Row className="info-wrapper" direction="vertical">
          <Col sm={24} md={18} lg={10}>
            <div className="header-logo-wrapper">
              <img
                className="header-logo"
                src={header_image?.localFile?.publicURL}
                alt={title}
              />
            </div>
            <div>
              <h1 className="title">{title}</h1>
            </div>
            <div>
              <h2 className="subtitle">{subtitle}</h2>
            </div>
            <div>
              <p className="description">{short_description}</p>
            </div>
            <Space className="action-options" direction="horizontal">
              <div>
                <Duration totalTime={duration} />
              </div>
              <div>
                <AuthorList authorList={authorList} />
              </div>
              <div>
                <Price
                  price={price}
                  pricePolicy={price_policy}
                  currency={currency}
                />
              </div>
            </Space>
            <div>{videoActionButtons}</div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default VideoHeader

VideoHeader.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  canPurchase: PropTypes.bool,
  canPlay: PropTypes.bool,
  onPurchase: PropTypes.func,
  onPlay: PropTypes.func,
  onShowMoreInfo: PropTypes.func,
  onToggleFavorite: PropTypes.func,
  isFavorite: PropTypes.bool,
  showFavoriteButton: PropTypes.bool,
  progress: PropTypes.number,
}
