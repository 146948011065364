import React from "react"
import Icon from "@ant-design/icons"

const SpeakerSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 44.524 39.24">
    <g
      id="Raggruppa_40"
      data-name="Raggruppa 40"
      transform="translate(30.794 12.02)"
    >
      <g id="Raggruppa_39" data-name="Raggruppa 39">
        <path
          id="Tracciato_9"
          data-name="Tracciato 9"
          d="M289.293,137.152c-.275-.275-.551-.606-.881-.881l-2.2,2.479a6.863,6.863,0,0,1,.606,9.64,3.157,3.157,0,0,1-.606.606l2.2,2.479A10.123,10.123,0,0,0,289.293,137.152Z"
          transform="translate(-286.208 -136.271)"
        />
      </g>
    </g>
    <g
      id="Raggruppa_42"
      data-name="Raggruppa 42"
      transform="translate(36.358 6.236)"
    >
      <g id="Raggruppa_41" data-name="Raggruppa 41">
        <path
          id="Tracciato_10"
          data-name="Tracciato 10"
          d="M341.335,83.613c-.386-.386-.771-.771-1.157-1.1l-2.259,2.424a14.88,14.88,0,0,1,.936,20.989c-.275.331-.606.606-.936.937l2.259,2.424A18.19,18.19,0,0,0,341.335,83.613Z"
          transform="translate(-337.92 -82.511)"
        />
      </g>
    </g>
    <g id="Raggruppa_44" data-name="Raggruppa 44" transform="translate(0 0)">
      <g id="Raggruppa_43" data-name="Raggruppa 43">
        <path
          id="Tracciato_11"
          data-name="Tracciato 11"
          d="M25.892,24.728a1.719,1.719,0,0,0-1.708.11L9.42,34.754H1.653A1.623,1.623,0,0,0,0,36.407V51.942a1.623,1.623,0,0,0,1.653,1.653H9.42L24.184,63.51A1.679,1.679,0,0,0,26.5,63.07a1.857,1.857,0,0,0,.275-.937V26.161A1.588,1.588,0,0,0,25.892,24.728Z"
          transform="translate(0 -24.552)"
        />
      </g>
    </g>
  </svg>
)

const SettingsSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 36.359 37.37">
    <g id="Raggruppa_45" data-name="Raggruppa 45" transform="translate(0)">
      <path
        id="Tracciato_12"
        data-name="Tracciato 12"
        d="M41.768,23.6l-3.942-3.092a14.673,14.673,0,0,0,.131-1.822,14.673,14.673,0,0,0-.131-1.822l3.952-3.092a.932.932,0,0,0,.224-1.2L38.265,6.1a.924.924,0,0,0-1.14-.4L32.473,7.577a14.178,14.178,0,0,0-3.158-1.84l-.7-4.951A.961.961,0,0,0,27.68,0H20.206a.943.943,0,0,0-.925.785l-.7,4.951a13.827,13.827,0,0,0-3.158,1.84L10.77,5.7a.935.935,0,0,0-1.14.4L5.893,12.575a.936.936,0,0,0,.224,1.2l3.942,3.092a14.673,14.673,0,0,0-.131,1.822,14.673,14.673,0,0,0,.131,1.822L6.118,23.6a.932.932,0,0,0-.224,1.2L9.63,31.269a.924.924,0,0,0,1.14.4l4.653-1.878a14.178,14.178,0,0,0,3.158,1.84l.7,4.951a.943.943,0,0,0,.925.785H27.68a.943.943,0,0,0,.925-.785l.7-4.951a13.827,13.827,0,0,0,3.158-1.84l4.653,1.878a.935.935,0,0,0,1.14-.4l3.737-6.474A.936.936,0,0,0,41.768,23.6ZM23.943,25.225a6.54,6.54,0,1,1,6.54-6.54A6.535,6.535,0,0,1,23.943,25.225Z"
        transform="translate(-5.768)"
      />
    </g>
  </svg>
)

const FullscreenSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 37.37 37.37">
    <path
      id="_003-maximize"
      data-name="003-maximize"
      d="M18.685,0A18.685,18.685,0,1,0,37.37,18.685,18.758,18.758,0,0,0,18.685,0ZM15.4,28.538H8.832V21.969h2.19v4.379H15.4Zm0-17.517H11.021V15.4H8.832V8.832H15.4ZM28.538,28.538H21.969v-2.19h4.379V21.969h2.19Zm0-13.138h-2.19V11.021H21.969V8.832h6.569Zm0,0"
    />
  </svg>
)

const ScreenLockSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 512 512">
    <path
      d="M405.333,170.667v-21.333C405.333,67.008,338.347,0,256,0S106.667,67.008,106.667,149.333v21.333
			c-35.285,0-64,28.715-64,64V448c0,35.285,28.715,64,64,64h298.667c35.285,0,64-28.715,64-64V234.667
			C469.333,199.381,440.619,170.667,405.333,170.667z M149.333,149.333c0-58.816,47.851-106.667,106.667-106.667
			s106.667,47.851,106.667,106.667v21.333H149.333V149.333z M426.667,448c0,11.776-9.579,21.333-21.333,21.333H106.667
			c-11.755,0-21.333-9.557-21.333-21.333V234.667c0-11.776,9.579-21.333,21.333-21.333h298.667c11.755,0,21.333,9.557,21.333,21.333
			V448z"
    />

    <path
      d="M256,245.333c-35.285,0-64,28.715-64,64c0,27.776,17.899,51.243,42.667,60.075v35.925
			c0,11.797,9.557,21.333,21.333,21.333c11.776,0,21.333-9.536,21.333-21.333v-35.925C302.101,360.576,320,337.109,320,309.333
			C320,274.048,291.285,245.333,256,245.333z M256,330.667c-11.755,0-21.333-9.557-21.333-21.333S244.245,288,256,288
			c11.755,0,21.333,9.557,21.333,21.333S267.755,330.667,256,330.667z"
    />
  </svg>
)

const PlaySvg = () => (
  <svg
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 98.604 109.433"
  >
    <g
      id="_005-play-button-arrowhead"
      data-name="005-play-button-arrowhead"
      transform="translate(-8.107)"
    >
      <path
        id="Tracciato_13"
        data-name="Tracciato 13"
        d="M25.972,2.413C16.106-3.246,8.107,1.39,8.107,12.76V96.665c0,11.381,8,16.011,17.865,10.358L99.309,64.964c9.869-5.661,9.869-14.833,0-20.493Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
)

const DurationSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 19.372 19.372">
    <g id="Raggruppa_30" data-name="Raggruppa 30">
      <path
        id="Tracciato_6"
        data-name="Tracciato 6"
        d="M9.686,0a9.686,9.686,0,1,0,9.686,9.686A9.7,9.7,0,0,0,9.686,0Zm0,17.255a7.569,7.569,0,1,1,7.569-7.569A7.577,7.577,0,0,1,9.686,17.255Z"
      />
      <path
        id="Tracciato_7"
        data-name="Tracciato 7"
        d="M258.43,144.135v-4.06a.821.821,0,1,0-1.641,0V144.4c0,.013,0,.025,0,.038a.816.816,0,0,0,.236.62l3.056,3.056a.821.821,0,0,0,1.16-1.16Z"
        transform="translate(-247.906 -134.438)"
      />
    </g>
  </svg>
)

const VideoCountSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 26.169 19.042">
    <g id="slideshow" transform="translate(0 -61.059)">
      <path
        id="Tracciato_12"
        data-name="Tracciato 12"
        d="M79.308,61.059H61.852a.793.793,0,0,0-.793.793V79.308a.793.793,0,0,0,.793.793H79.308a.793.793,0,0,0,.793-.793V61.852A.793.793,0,0,0,79.308,61.059Zm-.793,17.455H62.646V62.646H78.514Z"
        transform="translate(-57.885)"
      />
      <path
        id="Tracciato_13"
        data-name="Tracciato 13"
        d="M183.585,162.073a.793.793,0,0,0,.805-.022l6.347-3.969a.793.793,0,0,0,0-1.345l-6.347-3.966a.793.793,0,0,0-1.214.673v7.934A.794.794,0,0,0,183.585,162.073Zm1.178-7.2,4.057,2.535-4.057,2.537Z"
        transform="translate(-173.655 -86.832)"
      />
      <path
        id="path-1_42_"
        d="M457.941,91.588h1.587v15.869h-1.587Z"
        transform="translate(-433.358 -28.786)"
      />
      <path
        id="path-1_41_"
        d="M0,91.588H1.587v15.869H0Z"
        transform="translate(0 -28.786)"
      />
    </g>
  </svg>
)

const InformationSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 18.047 21">
    <g id="Raggruppa_12" data-name="Raggruppa 12">
      <g id="Raggruppa_11" data-name="Raggruppa 11" transform="translate(0)">
        <path
          id="Tracciato_3"
          data-name="Tracciato 3"
          d="M235.134,182.774c-.522,0-.893.22-.893.545v4.416c0,.278.371.556.893.556.5,0,.9-.278.9-.556v-4.416C236.038,182.994,235.632,182.774,235.134,182.774Z"
          transform="translate(-226.11 -175.277)"
        />
        <path
          id="Tracciato_4"
          data-name="Tracciato 4"
          d="M233.779,116.361a.835.835,0,1,0,.939.823A.9.9,0,0,0,233.779,116.361Z"
          transform="translate(-224.755 -111.588)"
        />
        <path
          id="Tracciato_5"
          data-name="Tracciato 5"
          d="M45.023,0a9.019,9.019,0,0,0-2.731,17.615l2.052,3.025a.82.82,0,0,0,1.358,0l2.052-3.025A9.019,9.019,0,0,0,45.023,0Zm2,16.123a.82.82,0,0,0-.458.329l-1.537,2.266-1.537-2.266a.82.82,0,0,0-.458-.329,7.383,7.383,0,1,1,3.99,0Z"
          transform="translate(-36 0)"
        />
      </g>
    </g>
  </svg>
)

const LevelSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 18.698 18.698">
    <path
      id="histogram"
      d="M14.315,0H4.382A4.387,4.387,0,0,0,0,4.382v9.933A4.387,4.387,0,0,0,4.382,18.7h9.933A4.387,4.387,0,0,0,18.7,14.315V4.382A4.387,4.387,0,0,0,14.315,0Zm2.922,14.315a2.925,2.925,0,0,1-2.922,2.922H4.382a2.925,2.925,0,0,1-2.922-2.922V4.382A2.925,2.925,0,0,1,4.382,1.461h9.933a2.925,2.925,0,0,1,2.922,2.922ZM10.079,3.944V14.79a.73.73,0,0,1-1.461,0V3.944a.73.73,0,1,1,1.461,0ZM7.267,6.5v8.29a.73.73,0,0,1-1.461,0V6.5a.73.73,0,0,1,1.461,0ZM4.419,11.723V14.79a.73.73,0,0,1-1.461,0V11.723a.73.73,0,0,1,1.461,0Zm8.509-3.031v6.1a.73.73,0,0,1-1.461,0v-6.1a.73.73,0,0,1,1.461,0Zm2.848,4.492V14.79a.73.73,0,0,1-1.461,0V13.183a.73.73,0,1,1,1.461,0Z"
    />
  </svg>
)

const LoadingLogoSvg = () => (
  <svg width="132.207" height="153.198" viewBox="0 0 132.207 153.198">
    <g
      id="Group_413"
      data-name="Group 413"
      transform="translate(-127.3 -307.318)"
    >
      <g
        id="Group_412"
        data-name="Group 412"
        transform="translate(-622.565 178.574)"
      >
        <g
          id="Group_411"
          data-name="Group 411"
          transform="translate(749.865 128.744)"
        >
          <path
            id="Path_122"
            data-name="Path 122"
            d="M822.485,302.139H817.1v36.982h5.065a10.563,10.563,0,0,1-.007,21.126H797.74a10.575,10.575,0,0,1-10.563-10.563H749.865a47.6,47.6,0,0,0,47.545,47.545h25.075a47.545,47.545,0,1,0,0-95.09Z"
            transform="translate(-749.865 -244.031)"
            fill="#fff"
          />
          <path
            id="Path_123"
            data-name="Path 123"
            d="M878.885,213.083H854.47a19.281,19.281,0,1,0,0,38.562h12.207v-8.718H854.47a10.563,10.563,0,1,1,0-21.126h24.415a10.575,10.575,0,0,1,10.563,10.563h8.719A19.3,19.3,0,0,0,878.885,213.083Z"
            transform="translate(-806.595 -184.819)"
            fill="#f3ea00"
          />
          <path
            id="Path_124"
            data-name="Path 124"
            d="M850.243,171.094H824.355a33.353,33.353,0,0,0,0,66.705H837.3v-8.718H824.355a24.634,24.634,0,0,1,0-49.269h25.887a24.663,24.663,0,0,1,24.635,24.634H883.6A33.391,33.391,0,0,0,850.243,171.094Z"
            transform="translate(-777.217 -156.902)"
            fill="#f3ea00"
          />
          <path
            id="Path_125"
            data-name="Path 125"
            d="M822.485,128.744H797.41a47.541,47.541,0,0,0-.406,95.08v.01h12.944v-8.718H797.41a38.827,38.827,0,1,1,0-77.653h25.075a38.87,38.87,0,0,1,38.827,38.827h8.718A47.6,47.6,0,0,0,822.485,128.744Z"
            transform="translate(-749.865 -128.744)"
            fill="#f3ea00"
          />
        </g>
        <path
          id="Path_126"
          data-name="Path 126"
          d="M9.723,11.8v1.881H6.178v8.91H4.189v-8.91H.69V11.8Zm13.766,0V22.591H21.609v-7.6l-2.775,7.6h-1.8l-2.775-7.6v7.6H12.421V11.8h2.544l2.991,8.247L20.946,11.8Z"
          transform="translate(858.582 117.441)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
)

const ToggleAudioOff = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 405.76 405.76">
    <path
      id="audio-off-1"
      d="M91.584,121.365h102.272l42.667-42.667H91.584c-23.531,0-42.667,19.115-42.667,42.667v144.939l42.667-42.667V121.365z"
    />
    <path
      id="audio-off-2"
      d="M380.139,124.565c-6.293-3.861-14.165-4.245-20.757-0.939l-54.464,27.221v-19.861L405.739,30.165L375.573,0
				L0.021,375.573l30.165,30.187l71.04-71.061h161.024c23.531,0,42.667-19.136,42.667-42.667v-29.461l54.464,27.2
				c3.008,1.515,6.272,2.261,9.536,2.261c3.904,0,7.787-1.045,11.221-3.179c6.272-3.883,10.112-10.752,10.112-18.155v-128
				C390.251,135.296,386.411,128.448,380.139,124.565z M143.893,292.032l118.357-118.379l0.021,118.379H143.893z M347.584,236.181
				l-42.667-21.333v-16.277l42.667-21.333V236.181z"
    />
  </svg>
)

const EventureSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18.321 23.484"
  >
    <g id="Group_415" data-name="Group 415" transform="translate(0)">
      <path
        id="Union_3"
        data-name="Union 3"
        d="M7.246,14.588A7.286,7.286,0,0,1,0,7.294H5.654a1.609,1.609,0,0,0,1.61,1.621h3.722a1.621,1.621,0,0,0,0-3.242h-.769V0h.823a7.294,7.294,0,0,1,.018,14.588Z"
        transform="translate(0.018 8.896)"
        fill="#fff"
      />
      <path
        id="Path_142"
        data-name="Path 142"
        d="M143.756,34.4h-3.722a2.954,2.954,0,0,0,0,5.907h1.879V38.974h-1.861a1.621,1.621,0,0,1,0-3.242h3.722a1.609,1.609,0,0,1,1.61,1.621h1.324A2.961,2.961,0,0,0,143.756,34.4Z"
        transform="translate(-132.752 -30.078)"
        fill="#f2ea00"
      />
      <path
        id="Path_143"
        data-name="Path 143"
        d="M133.618,22.5h-3.936a5.115,5.115,0,0,0,0,10.229h1.968V31.4h-1.968a3.782,3.782,0,0,1,0-7.564h3.936a3.769,3.769,0,0,1,3.757,3.782H138.7A5.106,5.106,0,0,0,133.618,22.5Z"
        transform="translate(-122.489 -20.321)"
        fill="#f2ea00"
      />
      <path
        id="Path_144"
        data-name="Path 144"
        d="M123.857,10.4h-3.811a7.294,7.294,0,0,0-.072,14.588h1.968V23.655h-1.914a5.943,5.943,0,0,1,0-11.886h3.811a5.93,5.93,0,0,1,5.9,5.943h1.324A7.225,7.225,0,0,0,123.857,10.4Z"
        transform="translate(-112.8 -10.4)"
        fill="#f2ea00"
      />
    </g>
  </svg>
)

export const SpeakerIcon = props => <Icon component={SpeakerSvg} {...props} />
export const ScreenLockIcon = props => (
  <Icon component={ScreenLockSvg} {...props} />
)
export const SettingsIcon = props => <Icon component={SettingsSvg} {...props} />
export const FullscreenIcon = props => (
  <Icon component={FullscreenSvg} {...props} />
)

export const PlayIcon = props => <Icon component={PlaySvg} {...props} />
export const DurationIcon = props => <Icon component={DurationSvg} {...props} />
export const VideoCountIcon = props => (
  <Icon component={VideoCountSvg} {...props} />
)
export const InformationIcon = props => (
  <Icon component={InformationSvg} {...props} />
)
export const LevelIcon = props => <Icon component={LevelSvg} {...props} />
export const LoadingLogoIcon = props => (
  <Icon component={LoadingLogoSvg} {...props} />
)
export const ToggleAudioOffIcon = props => (
  <Icon component={ToggleAudioOff} {...props} />
)
export const EventureIcon = props => <Icon component={EventureSvg} {...props} />
